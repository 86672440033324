/*
 * @Author: N0ts
 * @Date: 2022-11-18 11:58:33
 * @Description: 码云工具
 * @FilePath: /vue/src/utils/giteeUtil.ts
 * @Mail：mail@n0ts.cn
 */

import http from "@/utils/http/axios";
import config from "@/config/config";
import data from "@/data/data";
import local from "@/utils/localData/local";
import notify from "@/utils/notify/notify";
import { Base64 } from "js-base64";
import backgroundUtil from "@/utils/backgroup";
import hitokoto from "@/utils/hitokoto";
import themeColor from "@/utils/themeColor";

/**
 * 获取码云用户 Token
 * @param code 回调 code
 */
function getGiteeToken(code: string) {
    http.post("https://gitee.com/oauth/token", {
        grant_type: "authorization_code",
        code,
        client_id: config.gitee.clientId,
        redirect_uri: config.gitee.redirectUri,
        client_secret: config.gitee.clientSecret
    })
        .then((res: any) => {
            data.saveData.gitee.GiteeToken = res.access_token;
            local.save();
            getGiteeUserInfo();
        })
        .catch(() => {
            notify("码云获取 Token 失效，请联系管理员", 4);
        });
}

/**
 * 获取码云用户信息
 */
function getGiteeUserInfo() {
    if (!data.saveData.gitee.GiteeToken) return;
    http.get(
        `https://gitee.com/api/v5/user?access_token=${data.saveData.gitee.GiteeToken}`
    ).then((res: any) => {
        // 存储码云昵称与头像
        data.saveData.gitee.giteeName = res.name;
        data.saveData.gitee.giteeAvatar = res.avatar_url;
        data.saveData.gitee.giteeUser = res.login;
        local.save();

        // 检查仓库是否存在 & 创建
        checkRepos();

        notify("登陆成功！", 1);
    });
}

/**
 * 检查仓库是否存在 & 创建
 */
async function checkRepos() {
    try {
        // 创建仓库
        const createRes: any = await http.post(
            "https://gitee.com/api/v5/user/repos",
            {
                access_token: data.saveData.gitee.GiteeToken,
                name: "IceCreamSearchData",
                description: "IceCreamSearch 起始页数据同步用",
                has_issues: "false",
                has_wiki: "false",
                can_comment: "false",
                auto_init: "true",
                private: "true"
            }
        );
        data.saveData.gitee.giteeRepos = createRes.name;

        // 上传数据
        pushDataToGitee();
    } catch (err: any) {
        // 仓库已存在
        if (err.message.includes("422")) {
            // 获取数据
            pullDataToGitee();
        } else {
            // 其他报错
            console.error("创建仓库异常！请联系管理员！" + err);
            notify("创建仓库异常！请联系管理员！", 4);
        }
    }
}

/**
 * 获取仓库数据
 */
async function pullDataToGitee() {
    try {
        // 获取 README 文件内容
        const readme: any = await getREADME();

        // 更新数据
        const cacheData = data.saveDataCache;
        data.saveDataCache = JSON.parse(Base64.decode(readme.content));

        // 重置设置
        reloadSetting(false);
        data.saveDataCache = cacheData;

        notify("个人数据获取成功！", 1);
    } catch (err) {
        console.log(err);
        notify("个人数据获取失败！", 4);
    }
}

/**
 * 获取 README 文件内容
 */
function getREADME() {
    return new Promise((resolve, reject) => {
        // 获取仓库 README
        http.get(
            `https://gitee.com/api/v5/repos/${data.saveData.gitee.giteeUser}/IceCreamSearchData/readme`,
            {
                params: {
                    access_token: data.saveData.gitee.GiteeToken
                }
            }
        )
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                if (err.message.includes("401")) {
                    notify("登陆已过期！请重新登陆！", 4);
                    return logout(true);
                }
                reject(err);
            });
    });
}

/**
 * 上传数据
 */
async function pushDataToGitee() {
    try {
        // 获取 README 文件内容
        const readme: any = await getREADME();

        // 上传 README
        await http.put(
            `https://gitee.com/api/v5/repos/${data.saveData.gitee.giteeUser}/IceCreamSearchData/contents/README.md`,
            {
                access_token: data.saveData.gitee.GiteeToken,
                content: Base64.encode(JSON.stringify(data.saveData)),
                sha: readme.sha,
                message: "IceCreamSearch Data Update"
            }
        );
        notify("数据上传成功！", 1);
    } catch (err) {
        notify("数据上传失败！", 4);
        console.log(err);
    }
}

/**
 * 注销
 */
function logout(log: boolean) {
    data.saveData.gitee = data.saveDataCache.gitee;
    local.save();
    if (!log) {
        notify("已注销！", 1);
    }
}

/**
 * 重置设置
 */
function reloadSetting(notifyType: boolean) {
    // 部分数据不需要清除
    let updateStorage = data.saveData.updateStorage;
    let gitee = data.saveData.gitee;

    // 深拷贝数据
    data.saveData = JSON.parse(JSON.stringify(data.saveDataCache));

    // 还原备份数据
    data.saveData.updateStorage = updateStorage;
    data.saveData.gitee = gitee;

    // bing 壁纸获取
    backgroundUtil.getBing();
    // 一言获取
    hitokoto();
    // 保存新数据
    local.save();
    // 主题色修改
    themeColor.change();

    if (notifyType) {
        notify("重置成功", 1);
    }
}

export default {
    getGiteeToken,
    getGiteeUserInfo,
    checkRepos,
    pullDataToGitee,
    getREADME,
    pushDataToGitee,
    logout,
    reloadSetting
};
