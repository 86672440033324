/*
 * @Author: N0ts
 * @Date: 2022-11-21 14:25:06
 * @Description: 背景图片工具
 * @FilePath: /vue/src/utils/backgroup.ts
 * @Mail：mail@n0ts.cn
 */

import http from "@/utils/http/axios";
import data from "@/data/data";
import notify from "@/utils/notify/notify";
import {AxiosResponse} from "axios";

export default {
    /**
     * 获取 Bing 壁纸
     */
    getBing() {
        interface BingApiResponse {
            images: Array<any>; // 更具体的类型取决于Bing API返回的图像数据结构
        }
        http.post("https://cros.noid.work/cors", {
            method: "get",
            url: "https://cn.bing.com/HPImageArchive.aspx",
            params: {
                format: "js",
                idx: 0,
                n: 1,
                mkt: "zh-CN"
            }
        })
            .then((res: AxiosResponse<BingApiResponse>) => {
                console.log(res.data);
                data.bingData = res.data.images;
                // 如果已经设置壁纸则跳过
                if (data.saveData.bgLink) {
                    return this.setBg(data.saveData.bgLink);
                }

                // 设置第一张图片
                data.saveData.bingIndex = 0;
                data.saveData.bgLinkContent = data.bingData[0].copyright;
                data.saveData.bgLinkHref = data.bingData[0].copyrightlink;
                data.saveData.bgLink =
                    "https://cn.bing.com/" + data.bingData[0].url;
            })
            .catch((err) => {
                notify("获取 bing 壁纸失败", 4);
            });
    },

    /**
     * 设置背景样式
     */
    setBg(url: string) {
        data.backgroundCss = `background: url("${url}") center center / cover fixed;`;
    }
};
